import {
    AuthenticationDetails,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserPool,

} from 'amazon-cognito-identity-js';
import {
    getRefreshToken as apiGetRefreshToken,
    loginSchoolshub as apiLoginSchoolshub,
    logoutSchoolshub as apiLogoutSchoolshub,
} from './api';

const CUSTOM_EMAIL_SENDER_CLTP_APP = 'cltp';
const CUSTOM_EMAIL_SENDER_RESET_PASSWORD_ACTION = 'resetPassword';

const isDev = 'development' === process.env.NODE_ENV;

const LOCAL_STORAGE_REFRESH_TOKEN_KEY = 'cltp-refresh-token';
const LOCAL_STORAGE_LAST_USED_EMAIL_KEY = 'cltp-last-used-email';

const userPool = new CognitoUserPool({
    UserPoolId: '' !== process.env.REACT_APP_COGNITO_USER_POOL_ID ? process.env.REACT_APP_COGNITO_USER_POOL_ID : 'eu-west-2_EBx3FmqTg', // Will be replaced by sed during build
    ClientId: '' !== process.env.REACT_APP_COGNITO_CLIENT_ID ? process.env.REACT_APP_COGNITO_CLIENT_ID : '62so363jdmth1c5cdb0t72k2mu', // Will be replaced by sed during build
});

let cognitoUser;

const cognitoUserFromEmail = (email) => cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
});

const getCognitoUser = () => {
    return cognitoUser ?? cognitoUserFromEmail(getLastUsedEmail());
};
const clearCognitoUser = () => cognitoUser = undefined;

export const login = (email, password) => new Promise((resolve, reject) => {
    const cognitoUser = cognitoUserFromEmail(email);

    cognitoUser.authenticateUser(new AuthenticationDetails({
        Username: email,
        Password: password,
    }), {
        onSuccess: (result) => {
            setLastUsedEmail(email);
            setRefreshToken(result.getRefreshToken());

            apiLoginSchoolshub({
                token: result.getIdToken().getJwtToken(),
                data: {
                    refreshToken: result.getRefreshToken().getToken(),
                    accessToken: result.getAccessToken().getJwtToken(),
                },
            });

            return resolve(result.getIdToken().getJwtToken());
        },
        onFailure: (error) => {
            isDev && console.error(error);
            return reject(error);
        },
    });
});

export const refreshToken = () => new Promise((resolve, reject) => {
    getRefreshToken()
        .then((currentRefreshToken) => {
            if (!currentRefreshToken) {
                return reject(new Error('No refresh token found'));
            }

            const cognitoUser = getCognitoUser();

            cognitoUser.refreshSession(currentRefreshToken, (error, session) => {
                if (error) {
                    let localToken = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);

                    if (null != localToken) {
                        clearRefreshToken();

                        return refreshToken();
                    }

                    isDev && console.error(error);
                    return reject(error);
                }

                setRefreshToken(session.getRefreshToken());
                return resolve(session.getIdToken().getJwtToken());
            });
        });
});

export const forgotPassword = (email) => new Promise((resolve, reject) => {
    const cognitoUser = cognitoUserFromEmail(email);

    cognitoUser.forgotPassword(
        {
            onSuccess: (data) => {
                return resolve(data);
            },
            onFailure: (error) => {
                isDev && console.error(error);
                return reject(error);
            },
        },
        {
            app: CUSTOM_EMAIL_SENDER_CLTP_APP,
            action: CUSTOM_EMAIL_SENDER_RESET_PASSWORD_ACTION,
        },
    );
});

export const resetPassword = (email, newPassword, code) => new Promise((resolve, reject) => {
    const cognitoUser = cognitoUserFromEmail(email);

    cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: (success) => {
            return resolve(success);
        },
        onFailure: (error) => {
            isDev && console.error(error);
            return reject(error);
        },
    });
});

export const logout = () => new Promise((resolve, reject) => {
    const cognitoUser = getCognitoUser();

    cognitoUser.globalSignOut({
        onSuccess: () => {
            clearCognitoUser();
            clearRefreshToken();
            clearLastUsedEmail();

            // We are already doing the global logout here
            apiLogoutSchoolshub({ data: { global: false } })
                .catch(() => {/* Do nothing */});

            return resolve();
        },
        onFailure: (error) => {
            isDev && console.error(error);

            return reject(error);
        },
    });
});

const setRefreshToken = (refreshToken) => localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY, refreshToken.getToken());
const getRefreshToken = async () => {
    let token = localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);

    if (null == token) {
        token = await getRefreshTokenFromApi();
    }

    if (token) {
        try {
            return new CognitoRefreshToken({
                RefreshToken: token,
            });
        } catch (e) {
            return null;
        }
    }

    return null;
};
const getRefreshTokenFromApi = () => new Promise((resolve) => {
    apiGetRefreshToken({})
        .then((response) => {
            if (401 === response.status) {
                throw new Error(response.statusText);
            }

            setLastUsedEmail(response.data.email);
            resolve(response.data.refreshToken);
        })
        .catch(() => {
            resolve(null);
        })
    ;
});
const clearRefreshToken = () => localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN_KEY);

const setLastUsedEmail = (lastUsedEmail) => localStorage.setItem(LOCAL_STORAGE_LAST_USED_EMAIL_KEY, lastUsedEmail);
const getLastUsedEmail = () => localStorage.getItem(LOCAL_STORAGE_LAST_USED_EMAIL_KEY);
const clearLastUsedEmail = () => localStorage.setItem(LOCAL_STORAGE_LAST_USED_EMAIL_KEY, undefined);
